export enum Flags {
  SHOW_PREDICTIONS = 'show_predictions',
  SHOW_PRICING_INSIGHTS_TIME_SERIES_SALES = 'show_pricing_insights_time_series_sales',
  ENABLE_FAVORITES = 'enable_favorites',
  ENABLE_EXPORT = 'enable_export',
  SHOW_UPCOMING_EVENTS = 'show_upcoming_events',
  SHOW_EVENT_INSIGHTS = 'show_event_insights',
}

export type FlagResult = {
  value: string | number | boolean
  loading: boolean
}
