import { PropsWithChildren } from 'react'
import { RoleName } from 'carbonarc-ui'
import userGuide from '@assets/STAR V1 USERGUIDE.pdf'
import { useAuth } from '@components/Okta'
import { APP } from '@config/site/app'
import { AppPageMinimumRoleRequired, DocsMenu, FeaturesEnabled, InitialPage, MainNav } from '@config/site/app/config'
import { FallbackInitialPage } from '@config/site/app/config/initialPage'
import { IntegratedApps } from '@config/site/app/config/integratedApps'
import { AppConfigContext, DocsMenuPage, GetPageData, PageDataWithNewTab } from '@config/site/app/context'
import { Feature } from '@config/site/app/features'
import { FlagResult, Flags } from '@config/site/app/flags'
import { IntegratedApp, IntegratedAppsLinks } from '@config/site/app/integratedApps'
import { Divider, ExternalPage, Page, externalPageData, pageData } from '@config/site/app/pages'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { feedbackEmail } from '@utils/emails'

const clearUselessDividers = (pages: DocsMenuPage[]) => {
  const firstNonDivider = pages.findIndex((page) => page !== Divider)
  const pageList = pages.slice(firstNonDivider)
  return pageList
    .map((page, index) =>
      page === Divider && (index === pageList.length - 1 || pageList[index + 1] === Divider) ? null : page,
    )
    .filter((page) => page != null) as DocsMenuPage[]
}

const enabledOnApp = FeaturesEnabled[APP] || []
const featuresMap: Record<Feature, FlagResult> = Object.values(Feature).reduce((agg, feature) => {
  agg[feature] = {
    value: enabledOnApp.includes(feature),
    loading: false,
  }
  return agg
}, {} as Record<Feature, FlagResult>)

const integratedApps = IntegratedApps[APP] || []
const integratedAppsLinks = integratedApps.reduce((agg, app) => {
  const link = IntegratedAppsLinks[app]
  agg[app] = link
  return agg
}, {} as Record<IntegratedApp, string>)

export const AppConfigProvider = ({ children }: PropsWithChildren) => {
  const { isAtLeast, user } = useAuth()
  const showPredictionsFlag = import.meta.env.VITE_SHOW_PREDICTIONS === 'true'
  const showPricingInsightsTimeSeriesSalesFlag = true
  const showUpcomingEventsFlag = false
  const showEventInsightsFlag = false

  const externalLinks: Record<ExternalPage, string | null> = {
    [ExternalPage.GITBOOK]: userGuide,
  }

  const flags: Record<Flags, FlagResult> = {
    [Flags.SHOW_PREDICTIONS]: { value: showPredictionsFlag, loading: false },
    [Flags.SHOW_PRICING_INSIGHTS_TIME_SERIES_SALES]: { value: showPricingInsightsTimeSeriesSalesFlag, loading: false },
    [Flags.ENABLE_FAVORITES]: featuresMap[Feature.FAVORITES],
    [Flags.ENABLE_EXPORT]: featuresMap[Feature.EXPORT],
    [Flags.SHOW_UPCOMING_EVENTS]: { value: showUpcomingEventsFlag, loading: false },
    [Flags.SHOW_EVENT_INSIGHTS]: { value: showEventInsightsFlag, loading: false },
  }

  const getPageData: GetPageData = (page: Page | ExternalPage) => {
    if (page in externalPageData) {
      const partialPageData = externalPageData[page as ExternalPage]
      const path = externalLinks[page as ExternalPage]
      if (!path) return undefined
      return {
        ...partialPageData,
        path,
        newTab: true,
        download: partialPageData.download,
      } as PageDataWithNewTab
    }
    return pageData[page as Page]
  }

  const getMinimumRoleForPage = (page: Page | ExternalPage) => {
    return AppPageMinimumRoleRequired[APP]?.[page]
  }

  const isLoadingPageFlag = (page: Page | ExternalPage) => {
    const pageData = getPageData(page)
    if (!pageData?.flag) return false
    const flag = flags[pageData.flag]
    return flag.loading
  }

  const canSeePage = (page: Page | ExternalPage) => {
    const minimumRoleRequired = getMinimumRoleForPage(page)
    if (!minimumRoleRequired) return false
    if (!isAtLeast(minimumRoleRequired)) return false
    const pageData = getPageData(page)
    if (!pageData?.flag) return true
    return !!flags[pageData.flag].value
  }

  const navBar =
    (MainNav[APP]?.filter((page) => page != null && canSeePage(page))
      .map(getPageData)
      .filter((p) => p != null) as PageDataWithNewTab[]) || []

  const supportEmailData = { label: 'Support', path: feedbackEmail(), icon: EnvelopeIcon }
  const docsMenu: DocsMenuPage[] = clearUselessDividers(
    (DocsMenu[APP]?.filter((page) => page === Divider || page === 'supportEmail' || (page != null && canSeePage(page)))
      .map((page) => (page === Divider ? Divider : page === 'supportEmail' ? supportEmailData : getPageData(page)))
      .filter((p) => p != null) as DocsMenuPage[]) || [],
  )

  const initialPage = InitialPage[APP]?.[user?.role as RoleName] ?? FallbackInitialPage[APP]
  const initialPath = initialPage ? getPageData(initialPage)?.path ?? '' : ''

  return (
    <AppConfigContext.Provider
      value={{
        externalLinks,
        flags,
        canSeePage,
        navBar,
        docsMenu,
        initialPath,
        getPageData,
        getMinimumRoleForPage,
        isLoadingPageFlag,
        appLinks: integratedAppsLinks,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  )
}
